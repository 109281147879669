/*@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@0;1&display=swap');*/

*,
.column.label p,
button.ui.button,
p.note,
.result-item a,
.results.page .header h1 {
  font-family: sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url('images/art.jpg') transparent !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  background-position: top right !important;
}

.button-wrapper {
  margin: 0.25em !important;
  float: left;
}

.page {
  display: flex;
  justify-content: center;
  padding-bottom: 4em;
}

.ui.grid.section {
  margin: 0 0 2em 0;
  padding: 0.4em;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.section {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid white;
  border-radius: 4px;
  margin-bottom: 1px;
}
.sectionHidden {
  display: none!important;
}


.slider-container {
  display: flex;
  align-items: center;
  width: 100%;
}


/* css fix for range */

.slider-container>div {
  width: 100%
}

.slider-container .slider {
  flex: 1 0 auto
}

.slider-container .label {
  flex: 0;
  padding: 0 1em;
}

.ui.grid>.row {
  padding: 0.1em !important;
}

.column {
  display: flex !important;
  align-items: center;
  padding: 0.6em 0;
}

.column.bold {
  font-weight: bold;
  font-size: 1.1rem;
}

p.note {
  padding: 1em;
  font-size: 1.1rem;
  border-radius: 4px;
}

p.note.center {
  text-align: center;
}

.search-button-container {
  padding: 1em;
  text-align: center;
}

.spinner {
  background: url('images/rolling.gif');
  background-repeat: no-repeat;
  background-position: center top;
  height: 120px;
}

.spinner.small {
  height: 32px;
  width: 32px;
  background-size: 32px;
}

.ui.loader {
  box-shadow: 0px 0px 20px #e4e4e4;
}

.ui.list.results {
  margin: 2em !important
}

.result-item .title {
  margin: 0;
  font-family: sans-serif;
  font-weight: normal;
  font-size: 1.4rem;
}

.result-item .contributor {
  margin-bottom: 0.4rem
}

.result-item a {
  color: #0080ff;
}

.result-item a:hover {
  color: black;
}

.results.page .header {
  text-align: center;
  margin-top: 2rem;
}

.ui.image.logo {
  background-color: white
}

.search.page .container {
  background-color: rgba(255, 255, 255, 0.8);
}

.results.page .container {
  background-color: rgba(255, 255, 255, 0.9);
}

.page-count {
  margin: 1em;
  height: 1em;
}

.page-count p {
  line-height: 1em;
}

.ui.loader {
  background-color: white;
  border-radius: 2em;
  border: 2em solid white;
}

.friendly.message {
  padding: 2em 3em;
}

.center.message {
  padding: 2em 3em;
  text-align: center;
}

.tools.right {
  text-align: right;
}
.tools.right .label {
  margin-right: 0.3em;
  font-size: 0.9em;
}

.ui.segment.message {
  border-width: 0px;
  box-shadow: none;
  color: #333;
}

.ui.segment.footer {
  background-color: #c5eaff;
}

.loader-container {
  position: relative;
}

.segment-title {
  color: #666
}

.zone-buttons .ui.button {
  margin-bottom: 4px;
  font-weight: normal;
}

.search-trove {
  background: #d2f2ff;
}


.tools.right .ui.button,
.ui.label {
  font-weight: normal !important;
}